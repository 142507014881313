<template>
  <div
    v-if="getStockMovement.data.relationships['product-batches'].data?.length"
  >
    <dt class="mt-4 text-sm font-bold text-gray-700">Batch</dt>
    <dd class="mt-1 text-sm text-gray-900">
      <Datatable :paginated="false" :footer="false">
        <template v-slot:thead>
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                No Batch
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Produk
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Kadaluwarsa
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Jumlah
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody>
          <tbody>
            <tr
              v-for="product in getStockMovement.data.relationships[
                'product-batches'
              ].data"
              :key="product.product_code"
              class="bg-white hover:bg-green-100"
            >
              <td
                class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
              >
                {{
                  getRelationships(getStockMovement, product.id).attributes
                    .no_batch
                }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
              >
                {{
                  getRelationships(
                    getStockMovement,
                    getRelationships(getStockMovement, product.id).relationships
                      .product.data.id
                  ).attributes.code
                }}
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                {{
                  getRelationships(getStockMovement, product.id).attributes
                    .expires_at
                }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
              >
                {{
                  getRelationships(getStockMovement, product.id).attributes
                    .product_qty | toCurrency
                }}
              </td>
            </tr>
          </tbody>
        </template>
      </Datatable>
    </dd>
  </div>
</template>
<script>
import Datatable from '@/components/base/Datatable.vue';
import { getRelationships } from '@/services/utils.service';
import { mapGetters } from 'vuex';

export default {
  name: 'SupplierBatchTable',
  components: {
    Datatable,
  },
  computed: {
    ...mapGetters({
      getStockMovement: 'stock_movements/getStockMovement',
    }),
  },
  methods: {
    getRelationships,
  },
};
</script>
