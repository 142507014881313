<template>
  <div>
    <BaseModal
      :showing="visible"
      @close="onClose"
      :showClose="visible"
      :backgroundClose="true"
      size="max-w-3xl"
    >
      <div class="space-y-6">
        <div class="flex items-center justify-between">
          <div>
            <h3 class="text-lg font-bold leading-6 text-gray-900">
              {{ title }}
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              {{ description }}
            </p>
          </div>
          <div>
            <img src="@/assets/images/logo.png" alt="" class="w-32" />
          </div>
        </div>
        <div class="border-t border-gray-200 pt-6" v-if="visible">
          <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
            <slot name="attribute" :stock-movement="getStockMovement">
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">
                  Nomor Pengiriman
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ getStockMovement.data.attributes.code }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Nomor Faktur</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{
                    getSingleIncluded(
                      getStockMovement,
                      getStockMovement.data.relationships['order'].data.id
                    ).attributes.origin_code
                  }}
                </dd>
              </div>
              <slot
                name="origin-office"
                :origin-office="
                  getSingleIncluded(
                    getStockMovement,
                    getStockMovement.data.relationships['origin-office'].data.id
                  )
                "
              >
                <div class="sm:col-span-2">
                  <dt class="text-sm font-bold text-gray-700">
                    Nomor Pusat / KP
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{
                      getSingleIncluded(
                        getStockMovement,
                        getStockMovement.data.relationships['origin-office']
                          .data.id
                      ).attributes.code
                    }}
                  </dd>
                </div>
              </slot>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Tanggal</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ getStockMovement.data.attributes.updatedAt | formatDate }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Total Barang</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{
                    Object.keys(
                      getStockMovement.data.attributes.product_summaries
                    ).length
                  }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Status</dt>
                <dd
                  v-if="viewAs === 'destination'"
                  class="mt-1 text-sm text-gray-900"
                >
                  <base-badge
                    :color="
                      getStockMovement.data.attributes.is_received
                        ? 'green'
                        : 'red'
                    "
                  >
                    {{
                      getStockMovement.data.attributes.is_received
                        ? 'Diterima'
                        : 'Belum Diterima'
                    }}</base-badge
                  >
                </dd>
                <dd v-else class="mt-1 text-sm text-gray-900">
                  <base-badge
                    :color="
                      getStockMovement.data.attributes.is_shipped
                        ? 'green'
                        : 'red'
                    "
                  >
                    {{
                      getStockMovement.data.attributes.is_shipped
                        ? 'Dikirim'
                        : 'Belum Dikirim'
                    }}</base-badge
                  >
                </dd>
              </div>
              <slot
                name="additional-attribute"
                :stock-movement="getStockMovement"
              />
            </slot>
            <template v-if="forceDisplayFirstPacket">
              <div class="sm:col-span-6">
                <dt class="text-sm font-bold text-gray-700">Barang</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  <Datatable :paginated="false" :footer="false">
                    <template v-slot:thead>
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Kode
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Nama
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Jumlah
                          </th>
                          <th v-if="withDispute || withDetailBatch" class="w-1/12"></th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:tbody>
                      <tbody>
                        <template
                          v-for="(product, index) in getStockMovement.data
                            .attributes.packets[0].products"
                        >
                          <tr
                            :key="index"
                            class="cursor-pointer bg-white hover:bg-green-100"
                            @click="setSelectedMovementDetail(product)"
                          >
                            <td
                              class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                            >
                              {{ product.product_code }}
                            </td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                            >
                              {{ product.product_name }}
                            </td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                            >
                              {{ product.shipped_product_qty | toCurrency }}
                            </td>
                            <td
                              v-if="withDispute || withDetailBatch"
                              class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="h-4 w-4 transition-all"
                                :class="
                                  product.uuid === selectedMovementDetail.uuid
                                    ? 'rotate-180'
                                    : null
                                "
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                />
                              </svg>
                            </td>
                          </tr>
                          <template v-if="withDetailBatch && selectedMovementDetail.uuid === product.uuid">
                            <tr v-for="(batch, batchIndex) in  productBatchesKeyByProduct[`${product.product_code}-${product.product_type}`]" class="bg-gray-50" :key="`${index}-batch-${batchIndex}`">
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                              >
                                {{ batch.attributes.no_batch }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                              >
                                {{ batch.attributes.expires_at | formatDate }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                                coslpan=2
                              >
                                {{ batch.attributes.product_qty | toCurrency }}
                              </td>
                              <td></td>
                            </tr>
                          </template>
                          <tr v-if="withDispute" :key="product.uuid">
                            <td
                              colspan="2"
                              class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                            >
                              Masukkan jumlah barang diterima
                            </td>
                            <td
                              colspan="3"
                              class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                            >
                              <base-input
                                type="text"
                                label="Jumlah Barang"
                                placeholder="Masukkan Jumlah Barang"
                                id="jml_barang"
                                with-label
                                inset
                                fullwidth
                                @change="onChangeBarang(product)"
                                v-model="product.received_product_qty"
                              />
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </template>
                  </Datatable>
                </dd>
              </div>
            </template>
            <slot v-else name="attribute" :stock-movement="getStockMovement">
              <div class="sm:col-span-6">
                <dt class="text-sm font-bold text-gray-700">Koli</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  <Datatable :paginated="false" :footer="false">
                    <template v-slot:thead>
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Kode
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Nama
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Total Barang
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:tbody>
                      <tbody>
                        <tr
                          class="cursor-pointer bg-white hover:bg-green-100"
                          v-for="(packet, index) in getStockMovement.data
                            .attributes.packets"
                          :key="index"
                          @click="onDetail(packet)"
                        >
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{ packet.code }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{ packet.name }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                          >
                            {{ packet.products.length | toCurrency }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </Datatable>
                </dd>
              </div>
            </slot>
          </dl>
        </div>
        <SupplierBatchTable v-if="withBatches" />

        <div class="flex justify-end gap-x-2">
          <base-button
            v-if="withPilahBarang"
            @click="onPilahBarang"
            :disabled="disableClassified.value"
            :class="disableClassified.classes"
          >
            Pilah Barang
            <p
              v-if="disableClassified.value"
              class="tooltiptext tooltiptext-top"
            >
              {{ disableClassified.message }}
            </p>
          </base-button>
          <base-button
            v-if="withDispute"
            @click="onCreateDispute"
            type="button"
            color="danger"
          >
            Laporkan
          </base-button>
          <base-button
            v-if="withConfirm && !getStockMovement.data.attributes.is_received"
            @click="onReceive"
            type="button"
          >
            Terima
          </base-button>
          <base-button
            v-if="withSend && !getStockMovement.data.attributes.is_shipped"
            @click="onSend"
            type="button"
          >
            Kirim
          </base-button>
          <base-button
            v-if="
              withValidate &&
              !getStockMovement.data.attributes.is_valid_for_shipment
            "
            @click="onValidate"
            type="button"
          >
            Validasi
          </base-button>
        </div>
      </div>
    </BaseModal>
    <BaseModal
      :showing="packetDetailModal.visible"
      @close="packetDetailModal.visible = false"
      :showClose="true"
      :backgroundClose="true"
      size="max-w-2xl"
    >
      <div class="space-y-6">
        <div class="flex items-center justify-between">
          <div>
            <h3 class="text-lg font-bold leading-6 text-gray-900">
              Detail Koli
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Data koli dari Pusat dan KP
            </p>
          </div>
          <div>
            <img src="@/assets/images/logo.png" alt="" class="w-32" />
          </div>
        </div>
        <div class="border-t border-gray-200 pt-6">
          <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Kode</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ packetDetailModal.data.code }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Nama</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ packetDetailModal.data.name }}
              </dd>
            </div>
            <div class="sm:col-span-6">
              <dt class="text-sm font-bold text-gray-700">Barang</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <Datatable :paginated="false" :footer="false">
                  <template v-slot:thead>
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Kode
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Nama
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Jumlah
                        </th>
                        <th v-if="withDispute" class="w-1/12"></th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:tbody>
                    <tbody>
                      <template
                        v-for="(product, index) in packetDetailModal.data
                          .products"
                      >
                        <tr
                          :key="index"
                          class="cursor-pointer bg-white hover:bg-green-100"
                          @click="setSelectedMovementDetail(product)"
                        >
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{ product.product_code }} {{ product.is_bonus ? '(BONUS)' : '' }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{ product.product_name }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                          >
                            {{ product.shipped_product_qty | toCurrency }}
                          </td>
                          <td
                            v-if="withDispute"
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="h-6 w-6 transition-all"
                              :class="
                                product.uuid === selectedMovementDetail.uuid
                                  ? 'rotate-180'
                                  : null
                              "
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                              />
                            </svg>
                          </td>
                        </tr>
                        <tr v-if="withDispute" :key="product.uuid">
                          <td
                            colspan="2"
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            Masukkan jumlah barang diterima
                          </td>
                          <td
                            colspan="3"
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            <base-input
                              type="text"
                              label="Jumlah Barang"
                              placeholder="Masukkan Jumlah Barang"
                              id="jml_barang"
                              with-label
                              inset
                              fullwidth
                              @change="onChangeBarang(product)"
                              v-model="product.received_product_qty"
                            />
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </template>
                </Datatable>
              </dd>
            </div>
          </dl>
        </div>
        <div class="flex justify-end gap-x-2">
          <button
            v-if="withDispute"
            @click="onCreateComplain"
            :disabled="hasComplained"
            type="button"
            :class="
              hasComplained
                ? 'cursor-not-allowed bg-opacity-50'
                : 'hover:bg-red-700'
            "
            class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
          >
            Komplain
          </button>
          <button
            @click="packetDetailModal.visible = false"
            type="button"
            class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
          >
            Tutup
          </button>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import BaseModal from '@/components/base/BaseModal.vue';
import BaseBadge from '@/components/base/BaseBadge.vue';
import Datatable from '@/components/base/Datatable.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import SupplierBatchTable from '@/components/supplier/SupplierBatchTable.vue';

export default {
  name: 'StockMovementDetailModal',
  components: {
    BaseModal,
    BaseBadge,
    Datatable,
    BaseInput,
    BaseButton,
    SupplierBatchTable,
  },
  props: {
    title: {
      type: String,
      default: 'Detail Penerimaan Barang',
    },
    description: {
      type: String,
      default: 'Data penerimaan barang dari Pusat dan KP',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    withDispute: {
      type: Boolean,
      default: true,
    },
    withConfirm: {
      type: Boolean,
      default: true,
    },
    withSend: {
      type: Boolean,
      default: false,
    },
    withValidate: {
      type: Boolean,
      default: false,
    },
    withBatches: {
      type: Boolean,
      default: false,
    },
    withPilahBarang: {
      type: Boolean,
      default: false,
    },
    forceDisplayFirstPacket: {
      type: Boolean,
      default: false,
    },
    viewAs: {
      type: String,
      default: 'destination',
    },
    withDetailBatch: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'refresh'],
  data() {
    return {
      packetDetailModal: {
        visible: false,
        data: {},
      },
      selectedMovementDetail: {
        received_product_qty: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getStockMovement: 'stock_movements/getStockMovement',
    }),
    disableClassified() {
      const is_received = this.getStockMovement.data.attributes.is_received;
      const is_stock_classified =
        this.getStockMovement.data.attributes.is_stock_classified;

      const disabled = is_stock_classified || !is_received;

      const messages = {
        is_stock_classified: 'Pengiriman ini telah terpilah',
        is_received: 'Pengiriman ini belum diterima',
      };
      const classes = disabled ? 'opacity-50 cursor-not-allowed tooltip' : null;
      return {
        value: disabled,
        message:
          messages[is_stock_classified ? 'is_stock_classified' : 'is_received'],
        classes,
      };
    },
    hasComplained() {
      return this.getStockMovement.data.attributes.is_complained;
    },
    productBatchesKeyByProduct() {
      return this.getIncludedByType(this.getStockMovement, 'product-batches').reduce((prev, current) => {
        if (Object.hasOwn(prev, `${current.attributes.product_code}-${current.attributes.product_type}`)) {
          return {
            ...prev,
            [`${current.attributes.product_code}-${current.attributes.product_type}`]: [...prev[`${current.attributes.product_code}-${current.attributes.product_type}`], current]
          }
        } else {
          return {
            ...prev,
            [`${current.attributes.product_code}-${current.attributes.product_type}`]: [current]
          }
        }
      }, {})
    }
  },
  methods: {
    ...mapActions({
      receiveStockMovement: 'stock_movements/receiveStockMovement',
      sendStockMovement: 'stock_movements/sendStockMovement',
      createDisputes: 'disputes/createDisputes',
      updateMovementDetail: 'movement_details/updateMovementDetail',
      updateStockMovement: 'stock_movements/updateStockMovement',
      createAlert: 'alert/createAlert',
    }),
    async onChangeBarang(product) {
      await this.updateMovementDetail({
        data: {
          type: 'movement-details',
          id: product.uuid,
          attributes: {
            received_product_qty: parseInt(product.received_product_qty),
          },
        },
      });
      this.$emit('refresh');
    },
    onClose() {
      this.$emit('close');
    },
    onDetail(packet) {
      this.packetDetailModal = {
        visible: true,
        data: packet,
      };
    },
    async onReceive() {
      await this.receiveStockMovement({ id: this.getStockMovement.data.id });

      this.$emit('refresh');
    },
    async onSend() {
      const payload = {
        data: {
          type: 'stock-movements',
          id: this.getStockMovement.data.id,
          attributes: {
            taken_by: 'Superadmin',
          },
        },
      };

      await this.sendStockMovement(payload);

      this.$emit('refresh');
    },
    async onValidate() {
      const payload = {
        data: {
          type: 'stock-movements',
          id: this.getStockMovement.data.id,
          attributes: {
            is_valid_for_shipment: true,
          },
        },
      };

      await this.updateStockMovement(payload);

      this.$emit('refresh');
    },
    onCreateDispute() {
      this.createDisputes({
        payload: {
          data: {
            type: 'disputes',
            attributes: {
              title: 'disputes',
              message: 'disputes',
            },
            relationships: {
              'stock-movement': {
                data: {
                  type: 'stock-movements',
                  id: this.getStockMovement.data.id,
                },
              },
              order: {
                data: {
                  type: 'orders',
                  id: this.getSingleIncluded(
                    this.getStockMovement,
                    this.getStockMovement.data.relationships.order.data.id
                  ).id,
                },
              },
            },
          },
        },
      });
    },
    setSelectedMovementDetail(movementDetail) {
      if (movementDetail.uuid == this.selectedMovementDetail.uuid) {
        this.resetStockMovement();
        return;
      }
      this.selectedMovementDetail = {
        ...movementDetail,
      };
    },
    async onCreateComplain() {
      const res = await this.updateStockMovement({
        data: {
          type: 'stock-movements',
          id: this.getStockMovement.data.id,
          attributes: {
            complainSubmittedAt: dayjs().format(),
          },
        },
      });
      this.createAlert({
        data: res ? 'Berhasil mengajukan komplain' : 'Gagal membuat komplain',
        status: res ? 'success' : 'error',
      });
    },
    resetStockMovement() {
      this.selectedMovementDetail = {
        received_product_qty: null,
      };
    },
    onPilahBarang() {
      this.$emit('pilah-barang');
    },
  },
};
</script>
